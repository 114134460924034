export var featuresData = [
    {
        label: "Android Auto",
        value: "android auto"
    },
    {
        label: "Apple CarPlay",
        value: "apple carplay"
    },
    {
        label: "Bluetooth",
        value: "bluetooth"
    },
    {
        label: "Heated Seats",
        value: "heated seats"
    },
    {
        label: "Navigation",
        value: "navigation"
    },
    {
        label: "Parking Sensors",
        value: "parking sensors"
    },
    {
        label: "Sunroof",
        value: "sunroof"
    }, 
];
