export var doorsData = [
    {
        label: "2 doors",
        value: "2"
    },
    {
        label: "3 doors",
        value: "3"
    },
    {
        label: "4 doors",
        value: "4"
    },
    {
        label: "5 doors",
        value: "5"
    }, 
];
