// TODO: lowercase this variable and import tags from ../../index
export var tagsData = [
    {
        label: "Nearly new",
        value: "nearly-new"
    },
    {
        label: "Cars on offer",
        value: "cars-on-offer"
    },
    {
        label: "Deposit contribution",
        value: "deposit-contribution"
    },
    {
        label: "Available in store",
        value: "available-in-store"
    }, 
];
export var getCustomTagMessage = function(label) {
    var customMessages = new Map([
        [
            "Cars on offer",
            "Only show discounted cars"
        ],
        [
            "Available in store",
            "Only show cars I can view in store"
        ], 
    ]);
    var ref;
    return (ref = customMessages.get(label)) !== null && ref !== void 0 ? ref : label;
};
