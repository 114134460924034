import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var ellipses = "...";
var range = function(start, end) {
    var length = end - start + 1;
    return Array.from({
        length: length
    }, function(_, idx) {
        return idx + start;
    });
};
var getPageItemsRange = function(total, pageSize, currentPage) {
    var startMax = pageSize * (currentPage - 1) + 1;
    var endMin = pageSize * currentPage;
    var start = Math.max(startMax, 1);
    var end = Math.min(endMin, total);
    if (start > end) return {
        start: 0,
        end: 0
    };
    return {
        start: start,
        end: end
    };
};
export var usePagination = function(param) {
    var currentPage = param.currentPage, itemsPerPage = param.itemsPerPage, totalItemsCount = param.totalItemsCount;
    var siblingCount = 3;
    var totalPageCount = Math.ceil(totalItemsCount / itemsPerPage);
    var totalNumberOfPaginationItemsToShow = 10;
    var pageItemsRange = getPageItemsRange(totalItemsCount, itemsPerPage, currentPage);
    var isPageInvalid = currentPage > totalPageCount || currentPage < 1;
    if (totalNumberOfPaginationItemsToShow >= totalPageCount) {
        var allRange = range(1, totalPageCount);
        return {
            items: allRange,
            pageItemsRange: pageItemsRange,
            isPageInvalid: isPageInvalid
        };
    }
    var startIndex = Math.max(currentPage - siblingCount, 1);
    var endIndex = Math.min(currentPage + siblingCount, totalPageCount);
    var shouldShowLeftEllipses = startIndex > 2;
    var shouldShowRightEllipses = endIndex < totalPageCount - 2;
    var firstPageIndex = 1;
    var lastPageIndex = totalPageCount;
    if (!shouldShowLeftEllipses && shouldShowRightEllipses) {
        var leftItemCount = siblingCount + 2 * siblingCount;
        var leftRange = range(1, leftItemCount);
        var items = _to_consumable_array(leftRange).concat([
            ellipses,
            totalPageCount
        ]);
        return {
            items: items,
            pageItemsRange: pageItemsRange,
            isPageInvalid: isPageInvalid
        };
    }
    if (shouldShowLeftEllipses && !shouldShowRightEllipses) {
        var rightItemCount = 3 + 2 * siblingCount;
        var rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
        var items1 = [
            firstPageIndex,
            ellipses
        ].concat(_to_consumable_array(rightRange));
        return {
            items: items1,
            pageItemsRange: pageItemsRange,
            isPageInvalid: isPageInvalid
        };
    }
    if (shouldShowLeftEllipses && shouldShowRightEllipses) {
        var middleRange = range(startIndex, endIndex);
        var items2 = [
            firstPageIndex,
            ellipses, 
        ].concat(_to_consumable_array(middleRange), [
            ellipses,
            lastPageIndex, 
        ]);
        return {
            items: items2,
            pageItemsRange: pageItemsRange,
            isPageInvalid: isPageInvalid
        };
    }
    return {
        items: [],
        pageItemsRange: {
            start: 0,
            end: 0
        },
        isPageInvalid: isPageInvalid
    };
};
