export var fuelTypesData = [
    {
        label: "Petrol",
        value: "petrol"
    },
    {
        label: "Diesel",
        value: "diesel"
    },
    {
        label: "Electric",
        value: "electric",
        facetKeys: [
            "electric range extender"
        ]
    },
    {
        label: "Plug-in Hybrid",
        value: "plug-in hybrid",
        facetKeys: [
            "petrol plug-in hybrid",
            "diesel plug-in hybrid"
        ]
    },
    {
        label: "Petrol Hybrid",
        value: "petrol hybrid"
    },
    {
        label: "Diesel Hybrid",
        value: "diesel hybrid"
    }, 
];
